var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Vue } from "vue-property-decorator";
import { logout } from "@/views/login/Login";
import { resetCache } from "@/views/report/Index.vue";
import Action from "./components/Action.vue";
import UserHeader from "./components/UserHeader.vue";
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.data = null;
    }
    mounted() {
        this.load();
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = yield post("/api/Info");
        });
    }
    logout() {
        return __awaiter(this, void 0, void 0, function* () {
            if (yield this.confirm("確定登出 LeaPA？", "登出", "warning")) {
                resetCache();
                logout();
            }
        });
    }
    change() {
        this.$router.push('/info/changepassword');
    }
    changePin() {
        this.$router.push('/info/changePin');
    }
    delAccount() {
        this.$router.push('/info/delaccount');
    }
    lineAt() {
        window.open("https://lin.ee/kUcmeAE", "_blank");
    }
    scan() {
        this.$router.push('/info/scan');
    }
};
__decorate([
    Inject()
], default_1.prototype, "confirm", void 0);
default_1 = __decorate([
    Component({ components: { Action, UserHeader } })
], default_1);
export default default_1;
