
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";
	import { Html5Qrcode } from "html5-qrcode";

	let scanner: Html5Qrcode | null = null;

	@Component
	export default class Scanner extends Vue {

		protected error: boolean = false;
		private processing: boolean = false;

		@Inject() public readonly alert!: AlertMethod;
		@Inject() public readonly confirm!: ConfirmMethod;

		@Prop() public callback!: (msg: string) => Promise<void>;

		mounted(): void {
			this.launch();
		}

		async beforeDestroy(): Promise<void> {
			if(scanner) await scanner.stop();
			scanner = null;
		}

		private async launch() {
			try {
				scanner = new Html5Qrcode("qr-reader", undefined);
				await scanner.start(
					{ facingMode: { exact: "environment" } },
					{ fps: 10, qrbox: 250, aspectRatio: 1.2 },
					msg => this.onScan(msg),
					undefined
				);

				// Fix shade
				var q = document.getElementById("qr-shaded-region");
				var v = document.querySelector("video");
				if(q && v) q.style.height = getComputedStyle(v, null).height;
			} catch(e) {
				this.error = true;
			}

			// 開啟底下這一行可以進行偵錯測試
			// setTimeout(()=>this.scanCore("83886335"),3000);
		}

		private async onScan(msg: string): Promise<void> {
			if(this.processing) return;
			this.processing = true;
			await this.callback(msg);
			this.processing = false;
		}
	}
