import { render, staticRenderFns } from "./DelAccount.vue?vue&type=template&id=2be7f76d&"
import script from "./DelAccount.vue?vue&type=script&lang=ts&"
export * from "./DelAccount.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports