

	import { Component, Inject, Vue } from "vue-property-decorator";
	import * as bootstrap from 'bootstrap';

	@Component
	export default class extends Vue {
		@Inject() public readonly alert!: AlertMethod;

		private modal: bootstrap.Modal = null!;

		mounted(): void {
			this.modal = new bootstrap.Modal(this.$el, { backdrop: 'static' });
		}

		public show(): Promise<string | null> {
			return new Promise(resolve => {
				this.code = null;
				this.$el.addEventListener('hidden.bs.modal', () => resolve(this.code), { once: true });
				this.modal.show();
			});
		}

		protected code: string | null = null;

		public cancel(): void {
			this.code = null;
			this.modal.hide();
		}
	}
